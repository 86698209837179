import React, { useEffect } from "react"
import Hall from "../components/hall/Hall"
import "../assets/styles/_index.scss"
import Favicon from "react-favicon"
import { Helmet } from "react-helmet"
import TagManager from 'react-gtm-module'

const favicon_logo = require("../images/favicon.png");

const IndexPage = () => {

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-J9C48LD' });
  }, []);

  return (
    <>
      <Favicon url={favicon_logo} />

      <Helmet>
        <meta charSet="utf-8" />
        <title>Propverse - Powered by Propex.AI</title>
        <link rel="canonical" href="https://www.propverse.ai/" />
      </Helmet>

      <Hall />

    </>
  )
}

export default IndexPage

import React, { Component } from "react"
import "./assets/styles/_index.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import { Link } from "gatsby"
import VideoPlayer from "../videoplayer/VideoPlayer"
// const ROOT_URL = 'https://www.propverse.ai';

const developers1 = require("/utility/assets/homepage/developers/Abhee-Ventures.png")
const developers2 = require("/utility/assets/homepage/developers/Arsis.png")
const developers3 = require("/utility/assets/homepage/developers/Assetz.png")
const developers4 = require("/utility/assets/homepage/developers/BCD.png")
const developers5 = require("/utility/assets/homepage/developers/Birla-Estates.png")
const developers6 = require("/utility/assets/homepage/developers/Brigade.png")
const developers7 = require("/utility/assets/homepage/developers/Casagrand.png")
const developers8 = require("/utility/assets/homepage/developers/Geown.png")
const developers9 = require("/utility/assets/homepage/developers/Goorej.png")
const developers10 = require("/utility/assets/homepage/developers/Infracon-Pvt.png")

const developers11 = require("/utility/assets/homepage/developers/Maa-Homes.png")
const developers12 = require("/utility/assets/homepage/developers/Preeti-Developers.png")
const developers13 = require("/utility/assets/homepage/developers/Ramky.png")
const developers14 = require("/utility/assets/homepage/developers/Sowparnika.png")
const developers15 = require("/utility/assets/homepage/developers/Sanjeevini-Group.png")
const developers16 = require("/utility/assets/homepage/developers/Sattva.png")
const developers17 = require("/utility/assets/homepage/developers/Shoba.png")
const developers18 = require("/utility/assets/homepage/developers/Provident.png")
const developers19 = require("/utility/assets/homepage/developers/Newry.png")
const developers20 = require("/utility/assets/homepage/developers/ColiveAarra.png")


const developers21 = require("/utility/assets/homepage/developers/Kaman.png")
const developers22 = require("/utility/assets/homepage/developers/Hardrock.png")
const developers23 = require("/utility/assets/homepage/developers/Elyasis.png")
const developers24 = require("/utility/assets/homepage/developers/Fortune.png")

const proptv = "https://www.propverse.ai/utility/assets/homepage/HallProptv.mp4"
const proptvThumbnail = require("/utility/assets/homepage/prop-tv.png")
const proptvThumbnailmob = require("/utility/assets/homepage/prop-tv-mob.png")
const proptvmob = "https://www.propverse.ai/utility/assets/homepage/HallProptv.mp4"
const logo = require("/utility/assets/homepage/logo.png")

const hall = require("/utility/assets/icons/exhibition-hall.png")
const desk = require("/utility/assets/icons/information-desk.png")


export class Hall extends Component {
  render() {

    return (
      <>
        <div className="HallSection d-none d-md-block">
          <Container fluid>
            <Row className="HallSectionRow">
              <Col lg={3} xs={5} className="p-0 order-2 order-md-2 order-lg-1">
                <ul className="HallSectionList">
                  <li>
                    <Link to="/developers/abhee-developer/">
                      <Image src={developers1} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/birla-estates-developer/">
                      <Image src={developers5} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/godrej-developer/">
                      <Image src={developers9} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/ramky-developer/">
                      <Image src={developers13} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/newry-developer/">
                      <Image src={developers19} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/arsis-developer/">
                      <Image src={developers2} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/brigade-developer/">
                      <Image src={developers6} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/maa-homes-developer/">
                      <Image src={developers11} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/sattva-developer/">
                      <Image src={developers16} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/sobha-developer/">
                      <Image src={developers17} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/kaman-developer/">
                      <Image src={developers21} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/hard-rock-developer/">
                      <Image src={developers22} fluid className="" />
                    </Link>
                  </li>
                </ul>

                <div className="ExhibitionHall">
                  <Link to="/exhibition-hall/">
                    <p>
                      Exhibition&nbsp;Hall <Image src={hall} fluid className="" />
                    </p>
                  </Link>
                </div>
              </Col>
              <Col lg={6} xs={12} className="p-0 order-1 order-md-1 order-lg-2">
                <div className="HallSectionMiddle">
                  <div className="d-none d-md-block">
                    <VideoPlayer
                      videoUrl={proptv}
                      thumbnailUrl={proptvThumbnail}
                      fluid
                    />
                  </div>
                  <div className="d-block d-md-none">
                    <VideoPlayer
                      videoUrl={proptvmob}
                      thumbnailUrl={proptvThumbnailmob}
                      fluid
                    />
                  </div>
                  {/* <video src={proptv} fluid className="d-none-mob" />
                                    <video src={proptvmob} fluid className="d-block d-md-none" /> */}
                  <Link to="/">
                    <Image
                      src={logo}
                      fluid
                      className="logo-section d-none-mob"
                    />
                  </Link>
                </div>
              </Col>
              <Col lg={3} xs={5} className="p-0 order-3 order-md-3 order-lg-3">
                <ul className="HallSectionList">
                <li>
                    <Link to="/developers/infracon-developer/">
                      <Image src={developers10} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/casagrand-developer/">
                      <Image src={developers7} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/preeti-developer/">
                      <Image src={developers12} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/sanjeevini-developer/">
                      <Image src={developers15} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/sowparnika-developer/">
                      <Image src={developers14} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/bcd-emprasa-developer/">
                      <Image src={developers4} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/geown-developer/">
                      <Image src={developers8} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/provident-developer/">
                      <Image src={developers18} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/assetz-developer/">
                      <Image src={developers3} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/aarra-developer/">
                      <Image src={developers20} fluid className="" />
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/developers/elyssian-developer/">
                      <Image src={developers23} fluid className="" />
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/developers/fortune-sensation-developer/">
                      <Image src={developers24} fluid className="" />
                    </Link>
                  </li>
                </ul>

                <div className="InformationDesk">
                  <Link to="/information-desk/">
                    <p>
                      <Image src={desk} fluid className="" /> Information&nbsp;Desk
                    </p>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>


{/* Mobile View */}

        <div className="HallSection d-block d-md-none">
          <Container fluid>
            <Row className="HallSectionRow">
              <Col lg={3} xs={5} className="p-0 order-2 order-md-2 order-lg-1">
                <ul className="HallSectionList">
                  <li>
                    <Link to="/developers/abhee-developer/">
                      <Image src={developers1} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/arsis-developer/">
                      <Image src={developers2} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/birla-estates-developer/">
                      <Image src={developers5} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/brigade-developer/">
                      <Image src={developers6} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/godrej-developer/">
                      <Image src={developers9} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/maa-homes-developer/">
                      <Image src={developers11} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/ramky-developer/">
                      <Image src={developers13} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/sattva-developer/">
                      <Image src={developers16} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/newry-developer/">
                      <Image src={developers19} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/sobha-developer/">
                      <Image src={developers17} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/kaman-developer/">
                      <Image src={developers21} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/hard-rock-developer/">
                      <Image src={developers22} fluid className="" />
                    </Link>
                  </li>
                </ul>

                <div className="ExhibitionHall">
                  <Link to="/exhibition-hall/">
                    <p>
                      Exhibition&nbsp;Hall <Image src={hall} fluid className="" />
                    </p>
                  </Link>
                </div>
              </Col>
              <Col lg={6} xs={12} className="p-0 order-1 order-md-1 order-lg-2">
                <div className="HallSectionMiddle">
                  <div className="d-none d-md-block">
                    <VideoPlayer
                      videoUrl={proptv}
                      thumbnailUrl={proptvThumbnail}
                      fluid
                    />
                  </div>
                  <div className="d-block d-md-none">
                    <VideoPlayer
                      videoUrl={proptvmob}
                      thumbnailUrl={proptvThumbnailmob}
                      fluid
                    />
                  </div>
                  <Link to="/">
                    <Image
                      src={logo}
                      fluid
                      className="logo-section d-none-mob"
                    />
                  </Link>
                </div>
              </Col>
              <Col lg={3} xs={5} className="p-0 order-3 order-md-3 order-lg-3">
                <ul className="HallSectionList">
                <li>
                    <Link to="/developers/infracon-developer/">
                      <Image src={developers10} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/bcd-emprasa-developer/">
                      <Image src={developers4} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/casagrand-developer/">
                      <Image src={developers7} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/geown-developer/">
                      <Image src={developers8} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/preeti-developer/">
                      <Image src={developers12} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/provident-developer/">
                      <Image src={developers18} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/sanjeevini-developer/">
                      <Image src={developers15} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/assetz-developer/">
                      <Image src={developers3} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/sowparnika-developer/">
                      <Image src={developers14} fluid className="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/developers/aarra-developer/">
                      <Image src={developers20} fluid className="" />
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/developers/elyssian-developer/">
                      <Image src={developers23} fluid className="" />
                    </Link>
                  </li> */}
                  <li>
                     <Link to="/developers/fortune-sensation-developer/"> 
                      <Image src={developers24} fluid className="" />
                     </Link> 
                  </li>
                </ul>

                <div className="InformationDesk">
                  <Link to="/information-desk/">
                    <p>
                      <Image src={desk} fluid className="" /> Information&nbsp;Desk
                    </p>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    )
  }
}

export default Hall
